import React from "react"
import PropTypes from "prop-types"

const NotFoundLayout = ({ children }) => (
  <>
        {children}
  </>
)

NotFoundLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NotFoundLayout
