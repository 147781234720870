import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-react-intl"

import NotFoundLayout from "../components/NotFoundLayout"
import Seo from "../components/Seo"
import Link from "../components/Link"

const NotFoundPage = ({ intl }) => (
  <NotFoundLayout>
    <Seo
      lang={intl.locale}
      title={intl.formatMessage({ id: "siteMetadata.title" })}
    />
    <div className="flex h-screen">
      <div className="container mx-auto my-20 max-w-md">
        <h1>
          <FormattedMessage id="not_found.header" defaultMessage="Page not found" />
        </h1>
        <h2 className="my-4">
          <FormattedMessage id="not_found.description" defaultMessage="Sorry 😔 — we couldn’t find what you were looking for." />
        </h2>
        <p>
            <FormattedMessage id="not_found.returnHome"
              defaultMessage="<link>{title} — Homepage</link>"
              values={{
                link: chunks => (<Link to="/">{chunks}</Link>),
                title: intl.formatMessage({ id: "siteMetadata.title" })
              }}
            />
        </p>
      </div>
    </div>
  </NotFoundLayout>
)

export default injectIntl(NotFoundPage)
